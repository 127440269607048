import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { Platform } from "react-native";
import { ChangeEvent } from "react";
export const baseURL = require("../../../framework/src/config.js").baseURL;
import { DropResult } from "react-beautiful-dnd"

interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
      | {
        id: number;
        file_name: string;
        file_url: string;
      }[]
      | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
  name: string;
  url: string;
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}

interface UploadedFiles {
  id: number;
  file_name: string;
  file_url: string;
}
interface Item {
  attributes: {
    field_type: string;
    field_name: string;
  };
}
interface FieldData {
  id: string,
  type: string,
  attributes: {
    id: number,
    field_type: string,
    field_name: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loader: boolean
  filesWeb: File[];
  files: DocumentPickerResponse[];
  uploadedFiles: IUploadedFile[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  profilePicture: string;
  croppedProfilePicture: string;
  crop: { x: number; y: number };
  zoom: number;
  aspect: number;
  cropping: boolean;
  croppingInProgress: boolean;
  croppedArea: { x: number; y: number; width: number; height: number } | null;
  imageStyle: {
    transform: string, width: string, height: string
  };
  fileUploadedWEb: UploadedFiles[]
  isCropped: boolean;
  fileUploadedShow: boolean;
  field_type: string[];
  ispdf: boolean;
  selectCategory: string;
  selectedImages: string[],
  formDataField: FieldData[],
  textFirldValue: string;
  answerField: Record<string, string | boolean | unknown>;
  interField: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  deleteSingleUploadedId: string = "";
  deleteAllUploadedId: string = "";
  getFormDataID: string = "";
  uploadProfileId: string = "";
  uploadFormDataid: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      profilePicture: "",
      croppedProfilePicture: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      loader: false,
      cropping: false,
      croppingInProgress: false,
      croppedArea: null,
      imageStyle: {
        transform: '', width: '', height: ''
      },
      fileUploadedWEb: [],
      isCropped: false,
      fileUploadedShow: false,
      field_type: [],
      ispdf: true,
      selectCategory: 'Select Category',
      selectedImages: [],
      answerField: {},
      formDataField: [{
        "id": "",
        "type": "",
        "attributes": {
          "id": 0,
          "field_type": "",
          "field_name": ""
        }
      }],
      textFirldValue: "",
      interField: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      const token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTgsImV4cCI6MTcxMzM0NTQ2NywidG9rZW5fdHlwZSI6ImxvZ2luIn0.Ta_6yC11sTZGSG6zqXA3C9l1YGzGofVN1bCUJEBMVnUr9iN1kNgqei3Vsu-9VLWHFBh6tjAIzVb8ZqH1HJJk6A"
        this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    
      if (apiRequestCallId) {
        if (apiRequestCallId === this.createBulkUploadCallId) {
          this.handleResponse(responseJson)
          this.fileStatus(responseJson)
        } else if (
          apiRequestCallId === this.getBulkUploadCallId &&
          responseJson !== undefined
        ) {
          !responseJson.errors &&
            this.setState({ uploadedFiles: responseJson, fileUploadedWEb: responseJson.data.attributes.documents });
        }
        this.otherApids(apiRequestCallId, responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getToken();
  };



  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
handleResponse=(responseJson:{
  message?:string;
  errors?:string
})=>{
  if (responseJson.message) {
    alert(responseJson.message)
  }
  else if (responseJson.errors) {
    alert("Please selecte valid file format")
  }
}
  fileStatus = (responseJson: { data: string; } | undefined) => {
    let filesStatus = this.state.filesStatus;
    if (responseJson && responseJson.data) {
      filesStatus[filesStatus.length - 1] = "success";
    } else {
      filesStatus[filesStatus.length - 1] = "failed";
    }
    this.setState({ filesStatus });
    this.uploadFile();
  }

  otherApids = (apiRequestCallId: string | undefined, responseJson: { message: string; data: FieldData[]; }) => {
    if (
      apiRequestCallId === this.deleteBulkUploadCallId &&
      responseJson !== undefined
    ) {
      this.showAlert("Alert", configJSON.deletedSuccessMsg);
      this.getUploadedFiles();
    }
    else if (apiRequestCallId === this.deleteSingleUploadedId) {
      alert(responseJson.message)
      this.getUploadedFiles()
    }
    else if (apiRequestCallId === this.deleteAllUploadedId) {
      this.getUploadedFiles()
      alert(responseJson.message)
    }
    else if (apiRequestCallId === this.getFormDataID) {
      this.setState({ formDataField: responseJson.data })
    }
    else if (apiRequestCallId === this.uploadFormDataid) {
      this.setState({formDataField:[]})
      alert("Form Submitted")
    }
    else if (apiRequestCallId === this.uploadProfileId) {
      alert("Image uploaded")
    }
  }

  uploadFile = () => {
    const length = this.isPlatformWeb()
      ? this.state.filesWeb.length
      : this.state.files.length;

    if (length > this.state.filesStatus.length) {
      this.uploadFileSingle(this.state.filesStatus.length);
      this.setState({ filesStatus: [...this.state.filesStatus, "uploading"] });
    }
  };

  uploadFileSingle = (index: number) => {
    const header = {
      token: this.state.token,
    };

    const formData = new FormData();

    formData.append("documents[]", this.state.filesWeb[index]);

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createBulkUploadCallId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBulkUploadEndpoint
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  };

  removeFileWeb = (index: number) => {
    this.setState({
      filesWeb: Array.from(this.state.filesWeb).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };

  removeFile = (index: number) => {
    this.setState({
      files: Array.from(this.state.files).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };

  clearAllFile = () => {
    this.setState({ filesWeb: [], files: [], filesStatus: [] });
  };

  onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ loader: true })
    let tempFile: File[] = [];
    if (event.target.files) {
      for (let iterator = 0; iterator < event.target.files.length; iterator++) {
        if (event.target.files[iterator].size > this.maxFileSize) {
          this.showAlert(
            "Alert",
            event.target.files[iterator].name + configJSON.fileSizeErrorMsg
          );
        } else {
          tempFile.push(event.target.files[iterator]);
        }
      }
    }
    tempFile.length > 0 &&
      this.setState({
        filesWeb: [...this.state.filesWeb, ...tempFile],
      });
    this.setState({ loader: false })

  };

  selectFiles = async () => {
    try {
      const pickerResult = await DocumentPicker.pickMultiple({
        presentationStyle: "fullScreen",
        copyTo: "cachesDirectory",
      });
      const tempFile: DocumentPickerResponse[] = pickerResult.filter((result) => {
        if (result.size && result.size > this.maxFileSize) {
          this.showAlert("Alert", result.name + configJSON.fileSizeErrorMsg);
          return false;
        } else {
          return true;
        }
      });

      this.setState({
        files: [...this.state.files, ...tempFile],
      });
    } catch (error) {
      this.showAlert("Alert", configJSON.downloadingFailedMsg);
    }
  };

  getUploadedFiles = () => {
    this.setState({ fileUploadedShow: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBulkUploadCallId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBulkUploadEndpoint
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  };

  deleteFile = (fileGroupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBulkUploadCallId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteBulkUploadEndpoint}/${fileGroupId}`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  };

  fileDownloadHandler = (filePath: string, fileName: string) => {
    if (Platform.OS !== "web") {
      try {
        const RNFS = require("react-native-fs");
        const dirPath = this.isPlatformiOS()
          ? RNFS.DocumentDirectoryPath
          : RNFS.DownloadDirectoryPath;
        const toFile = `${dirPath}/${Date.now()}${fileName}`;
        RNFS.downloadFile({
          fromUrl: baseURL + filePath,
          toFile,
        }).promise.then((response: IDownloadResponse) => {
          if (response.statusCode === 200) {
            this.showAlert("Alert", configJSON.downloadedSuccessMsg + toFile);
          } else {
            this.showAlert("Alert", configJSON.downloadingFailedMsg);
          }
        });
      } catch (error) {
        this.showAlert("Alert", configJSON.downloadingFailedMsg);
      }
    }
  };

  openUploadedFile = (file: { file_url: string; }) => {
    window.open(baseURL + file.file_url, '_blank')
  }
  removeSingleUploadedFile = (index: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSingleUploadedId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSingleUploadedEndPoint}/${index}`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  }
  removeAllUploaded = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllUploadedId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAllUploadedEndPoint}/12`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
    this.setState({ fileUploadedShow: false })
  }

  getFormData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTgsImV4cCI6MTcxMzM0NTQ2NywidG9rZW5fdHlwZSI6ImxvZ2luIn0.Ta_6yC11sTZGSG6zqXA3C9l1YGzGofVN1bCUJEBMVnUr9iN1kNgqei3Vsu-9VLWHFBh6tjAIzVb8ZqH1HJJk6A"
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFormDataID = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFormDataEndpoint
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getFormDataMethod
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  }

  getFileIcon = (fileName: string) => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (fileExtension) {
      if (fileExtension === 'pdf') {
        return true;
      } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        return false;
      }
    }
    return false;
  };

  reorderFiles = (startIndex: number, endIndex: number) => {
    const filesWebCopy = Array.from(this.state.filesWeb);
    const [removed] = filesWebCopy.splice(startIndex, 1);
    filesWebCopy.splice(endIndex, 0, removed);

    this.setState({
      filesWeb: filesWebCopy,
    });
  };

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    this.reorderFiles(result.source.index, result.destination.index);
  };

  handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ croppingInProgress: true })
      const file = event.target.files[0];
      const profileUrl = URL.createObjectURL(file)
      this.setState({ profilePicture: profileUrl, croppingInProgress: true })
    }
  };

  onCropChange = (crop: { x: number; y: number }) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };

  handleCropComplete = (croppedArea: { x: number; y: number; width: number; height: number }) => {
    this.setState({ croppedArea: croppedArea });

    const scale = 100 / croppedArea.width;
    const transform = {
      x: `${-croppedArea.x * scale}%`,
      y: `${-croppedArea.y * scale}%`,
      scale,
      width: "calc(100% + 0.5px)",
      height: "auto"
    };

    const imageStyle = {
      transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
      width: transform.width,
      height: transform.height
    };
    this.setState({ imageStyle: imageStyle });
  }


  handleSetProfilePicture = () => {
    this.setState({ croppedProfilePicture: this.state.profilePicture });
    this.setState({ croppingInProgress: false, isCropped: true, selectedImages: [this.state.croppedProfilePicture] });
  };

  removeProfile = () => {
    this.setState({ profilePicture: '', croppedProfilePicture: '', isCropped: false })
  }
  handleFetchFunction = () => {
    return new Promise<Blob>((resolve, reject) => {
      const xhrget = new XMLHttpRequest();
      xhrget.open('GET', this.state.croppedProfilePicture, true);
      xhrget.responseType = 'blob';
  
      xhrget.onload = function () {
        if (xhrget.status === 200) {
          resolve(xhrget.response);
        } else {
          reject(new Error('Failed to fetch image'));
        }
      };
  
      xhrget.onerror = function () {
        reject(new Error('Failed to fetch image'));
      };
  
      xhrget.send();
    });
  };
  


  uploadProfile = async () => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    const blob = await this.handleFetchFunction()

    const profilePictureFile = new File(
      [blob],
      'sample.png',
      {
        lastModified: new Date().getTime(),
        type: 'image/png',

      }
    );

    formData.append("profile_picture", profilePictureFile);

    const getBulkUploadMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadProfileId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_bulk_uploading/attachments/profile_picture"
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  };


  integerBoxOnChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<{ name?: string; value: unknown; checked?: boolean }>, keyIndex: string, keyIndex2: string, value: string, filed_type: string) => {
    const check= event.target?.checked ? "checked" : "not-checked" 
    this.setState(prevState => ({
      answerField: {
        ...prevState.answerField,
        [keyIndex]: value,
        [keyIndex2]: filed_type == "checkbox" ?check: event.target.value
      }
    }));
  };

  uploadFormData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const body = {
      "attachment_form_params":
        this.state.answerField
    }



    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFormDataid = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_bulk_uploading/attachments/make_attachment_form?token=${this.state.token}`
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  }
  // Customizable Area End
}
