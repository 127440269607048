import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface ValidResponseType {
  data: TermsAndConditions[]
}

export interface ErrorPayloadType {
  key: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>
}

interface TermsAndConditions {
  id: number;
  created_at: string;
  description: string;
  title: string;
  is_accepted: boolean;
  terms_and_condition_id: number;
  acceptance_required: boolean;
}

interface AcceptedBy {
  account_id: number;
  accepted_on: string;
  email: string;
}

interface TermsAndConditions {
  id: number;
  account_id: number | null;
  created_at: string;
  updated_at: string;
  description: string;
  title: string;
  acceptance_required: boolean;
  term_type: string | null;
}

interface Attributes {
  id: number;
  account_id: number;
  terms_and_condition_id: number;
  created_at: string;
  updated_at: string;
  is_accepted: boolean;
  is_read: boolean;
  accepted_by: AcceptedBy[];
  terms_and_conditions: TermsAndConditions;
}

interface AcceptedOrNotDataType {
  id: string;
  type: string;
  attributes: Attributes;
}

interface ApiResponseAcceptedOrNotDataType {
  data: AcceptedOrNotDataType[];
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  termsCondsData:TermsAndConditions[];
  isAgree: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  getTermsConditionApiCallId: string = "";
  getCheckTAndCAcceptedApiCallId: string = "";
  postAcceptTermsConditionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      termsCondsData:[],
      isAgree:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getTermsConditionDataShow();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsId: termsCondsId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getAccountGroups = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getTermsCondsList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsConds = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckBoxChange = (value: boolean) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const body = {
      id: this.state.termsConds?.id,
      is_accepted: value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setAcceptanceOfTermsCondsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTermsCondsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // -----------Web Part------------------------

  apiCallTermsConditions = async (valueData: {
    endPoint?: string,
    method?: string,
    body?: {},
    contentType?: string,
    type?: string,
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    const header = {
      "Content-Type": contentType,
      token: "guest"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponseTermsCondition = (responseJson: ValidResponseType & TermsAndConditions & ApiResponseAcceptedOrNotDataType) => {
    return responseJson && responseJson.data || responseJson.terms_and_condition_id;
  };

  isInValidResponseTermsCondition = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacksTermsCondition = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getTermsConditionApiCallId) {
      this.setState({
        termsConds: null
      });
    }
    if (apiRequestCallId === this.postAcceptTermsConditionApiCallId) {
      this.setState({
        termsConds: null
      });
    }
    if (apiRequestCallId === this.getCheckTAndCAcceptedApiCallId) {
      this.setState({
        termsConds: null
      });
    }
  }

  apiSuccessCallBacksTermsConditio = async (apiRequestCallId: string, responseJson: ValidResponseType & TermsAndConditions & ApiResponseAcceptedOrNotDataType) => {
    if (apiRequestCallId === this.getTermsConditionApiCallId) {
      this.setState({
        termsCondsData: responseJson.data
      },()=>{
        this.getCheckTermsConditioAcceptedOrNot();
      });
    }
    if (apiRequestCallId === this.postAcceptTermsConditionApiCallId) {
      if (responseJson.is_accepted) {
        this.handleNavigateFromTermsConditio("Home");
      } else {
        this.showAlert(
          'Error',
          "Not able to use the product or service."
        );
        this.getTermsConditionDataShow();
      }
    }
    if (apiRequestCallId === this.getCheckTAndCAcceptedApiCallId) {
      let acceptedOrNot = responseJson.data[0].attributes.is_accepted
        if (acceptedOrNot) {
          this.setState({ isAgree: true }, () => {
            this.showAlert(
              'Alert',
              "You have already accepted the Terms and Conditions."
            );
          });
        } else {
          this.setState({ isAgree: false });
        }
    }
  }

  handleNavigateFromTermsConditio = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }

  getTermsConditionDataShow = async () => {
    this.getTermsConditionApiCallId = await this.apiCallTermsConditions({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getTermsConditionApiEndPoint + "Specific",
      type: ""
    });
  };

  getCheckTermsConditioAcceptedOrNot = async () => {
    this.getCheckTAndCAcceptedApiCallId = await this.apiCallTermsConditions({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCheckTermsConditioAcceptedOrNotApiEndPoint+this.state.termsCondsData[0].id,
      type: ""
    });
  };

  postAcceptDataShow = async (request: string, termConditionId: number) => {
    let body = {
      terms_and_condition_id: termConditionId,
      is_accepted: request ? true : false
    }
    this.postAcceptTermsConditionApiCallId = await this.apiCallTermsConditions({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.postTermsConditionApiEndPoint,
      type: "",
      body: body
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponseTermsCondition(responseJson)) {
        this.apiSuccessCallBacksTermsConditio(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseTermsCondition(responseJson)) {
        this.apiFailureCallBacksTermsCondition(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
}
