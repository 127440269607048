Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emailnotifications2";
exports.labelBodyText = "emailnotifications2 Body";
exports.patchMethod = "PATCH";

exports.btnExampleTitle = "CLICK ME";

exports.postOrderData = "bx_block_email_notifications/send_email_notification";
exports.enableNotificationApi = "bx_block_email_notifications/settings";
// Customizable Area End