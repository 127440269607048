import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from 'yup';
import React from "react";
interface FormValue {
  email: string,
  comments: string
}

interface AddProductValue {
  email: string,
  comments: string,
  order_status: string,
  order_date: string,
  order_templates: string,
}

export interface ValidResponseType {
  message: string
}

export interface ErrorPayloadType {
  key: string;
  message: string
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>,
  success: Array<ErrorPayloadType>
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formValue: object;
  notificationType: boolean;
  notificationType1: boolean;
  notificationType2: boolean;
  orderTemplatesValue: unknown;
  emailTempletOptions:string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Emailnotifications2Controller extends BlockComponent<
  Props,
  S,
  SS
>{
  // Customizable Area Start
  getAccountGroupsCallId:string="";
  postDataApiCallId: string = "";
  addOrderDataApiCallId: string = "";
  enableNotificationApiCallId: string = "";
  getEmailTempleteId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

// Customizable Area Start
// Customizable Area End

this.subScribedMessages = [
  getName(MessageEnum.AccoutLoginSuccess),
  // Customizable Area Start
  getName(MessageEnum.RestAPIResponceMessage)
  // Customizable Area End
];

this.state = {
  txtInputValue: "",
  txtSavedValue: "A",
  enableField: false,
  // Customizable Area Start
  formValue:{},
  notificationType: true,
  notificationType1: true,
  notificationType2: true,
  orderTemplatesValue: "",
  emailTempletOptions:[]
  // Customizable Area End
};
runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

// Customizable Area Start
// Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
  let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

  this.showAlert(
    "Change Value",
    "From: " + this.state.txtSavedValue + " To: " + value
  );

  this.setState({ txtSavedValue: value });
}

// Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

  let responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  if (this.isValidResponseNotification(responseJson)) {
    this.apiSuccessCallBacksNotification(apiRequestCallId, responseJson);
  } else if (this.isInValidResponseNotification(responseJson)) {
    this.apiFailureCallBacksNotification(apiRequestCallId, responseJson);
  }
  else if(apiRequestCallId === this.getEmailTempleteId){
    this.setState({emailTempletOptions:responseJson.templates.data.map((template: { attributes: { name: string; }; }) => template.attributes.name)})
   }
}
// Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  validationSchemaConditions = () => {
    if(this.state.orderTemplatesValue === "payment_received" || this.state.orderTemplatesValue === "verification_email") {
      return Yup.object().shape({
        email: Yup.string().trim().matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email format'
        ).required('Email is required'),
        comments: Yup.string().trim().required("Comments is required"),
      })
    } else if (this.state.orderTemplatesValue === "order_processed" || this.state.orderTemplatesValue === "order_placed" || this.state.orderTemplatesValue === "order_status_changed") {
      return Yup.object().shape({
        email: Yup.string().trim().matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email format'
        ).required('Email is required'),
        order_status: Yup.string().trim().required("Order Status is required"),
        order_templates: Yup.string().trim().required("Order Templates is required"),
        order_date: Yup.date().required("Order Date is required"), // Validation for Order Date
      })
    } else {
      return Yup.object().shape({
        email: Yup.string().trim().matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email format'
        ).required('Email is required'),
        comments: Yup.string().trim().required("Comments is required"),
        order_status: Yup.string().trim().required("Order Status is required"),
        order_templates: Yup.string().trim().required("Order Templates is required"),
        order_date: Yup.date().required("Order Date is required"), // Validation for Order Date
      })
    }
  }

  handleChangeTemplate = (event:React.ChangeEvent<{ name?: string; value: string; }>,handleChange:Function)=>{
    this.setState({orderTemplatesValue : event.target.value})
    handleChange(event)
  }

  apiCallNotification = async (valueData: {
    endPoint?: string,
    method?: string,
    body?: {},
    contentType?: string,
    type?: string,
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    const header = {
      "Content-Type": contentType,
      token: "guest"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  addOrder = async (values: AddProductValue) => {
    let body 
      if(this.state.orderTemplatesValue === "payment_received" || this.state.orderTemplatesValue === "verification_email") 
      {

        body =
       { type: values.order_templates,
        email: values.email,
        "payment_details": {
          invoice_number: 65423156985,
          comment: values.comments
        }}
            }
      else if (this.state.orderTemplatesValue === "order_processed" || this.state.orderTemplatesValue === "order_placed" || this.state.orderTemplatesValue === "order_status_changed") 
      {
        body =
        {type: values.order_templates,
        email: values.email,
        "order_details": {
          "company_name": "company_name",
          "order_number": 123455687,
          "date": values.order_date,
          "price": 451684,
          "order_status": values.order_status,
          "address": "#123, behind ABCD school, Mumbai"
        }}
      } 
   
     else {
        body=
        {type: values.order_templates,
        email: values.email,
        "order_details": {
          "company_name": "company_name",
          "order_number": 123455687,
          "date": values.order_date,
          "price": 451684,
          "order_status": values.order_status,
          "address": "#123, behind ABCD school, Mumbai"
        }
        ,
        "payment_details": {
          invoice_number: 65423156985,
          comment: values.comments
        }}
      };
    this.postDataApiCallId = await this.apiCallNotification({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postOrderData,
      type: "",
      body: body
    });
  };

  handleChange = async (name: string) => {
    if (name == "payment_received") {
      this.setState({ notificationType: !this.state.notificationType }, this.enableDisableNotification);
    } if (name == "order_updates") {
      this.setState({ notificationType1: !this.state.notificationType1 }, this.enableDisableNotification);
    } if (name == "verification_email") {
      this.setState({ notificationType2: !this.state.notificationType2 }, this.enableDisableNotification);
    }

// Toggle the local state
// setIsChecked(prevState => !prevState);
  };

  enableDisableNotification = async () => {
    const body = {
      settings: [
        { name: "payment_received", enabled: this.state.notificationType },
        { name: "order_updates", enabled: this.state.notificationType1 },
        { name: "verification_email", enabled: this.state.notificationType2 },
      ]
    }
    this.enableNotificationApiCallId = await this.apiCallNotification({
      contentType: configJSON.validationApiContentType,
      method: configJSON.patchMethod,
      endPoint: configJSON.enableNotificationApi,
      type: "",
      body: body
    });
  }

  isValidResponseNotification = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.message
  };

  isInValidResponseNotification = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacksNotification = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.postDataApiCallId) {
      if(responseJson.errors.length==0){
        this.showAlert("Success","Email successfully sent");
      }
      else if(responseJson.success.length==0){
        this.showAlert("Error","Failed, kindly check logs for further info.")
      }
      else if(responseJson.success.length>0 && responseJson.errors.length>0){
        this.showAlert("Message",`Email sent to ${responseJson?.success.length} users; failed for ${responseJson?.errors.length} users. Kindly refer logs.`)
      }
    }
  }

  apiSuccessCallBacksNotification = async (apiRequestCallId: string, responseJson: ValidResponseType) => {

if (apiRequestCallId === this.postDataApiCallId) {
  this.showAlert(
    'Alert',
    'responseJson.message'
  );
}
if (apiRequestCallId === this.enableNotificationApiCallId) {
  this.showAlert(
    'Alert',
    responseJson.message
  );
}
  }

  getEmailTemplet=()=>{
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmailTempleteId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_email_notifications/email_templates"
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  }

  componentDidMount = async () => {
    this.getEmailTemplet();
  };
 
  dynamicForm=async(values: { email: string; comments: string; order_status: string; order_templates: string; order_date: string; })=>{
    if(this.state.orderTemplatesValue!=="verification_email" && this.state.orderTemplatesValue!=="payment_received" && this.state.orderTemplatesValue!=="order_processed" && this.state.orderTemplatesValue!=="order_placed" && this.state.orderTemplatesValue!=="order_status_changed")
    {
      const body= this.state.orderTemplatesValue === "no_placeholder" ?{
        "type": this.state.orderTemplatesValue,
        "email" : values.email,
          "dynamic_contents": 
              {
                  "21": [
                     
                  ]
              }
      } : 
      {
        "type": this.state.orderTemplatesValue,
        "email" : values.email,
          "dynamic_contents": 
              {
                  "19": [
                      "max", 123123, "some comment"
                  ]
                  ,
                  "21": [
                      "lewis", 123453, "some comment"
                  ]
                  ,
                  "22": [
                      "test", 2222, "some information", "information"
                  ]
              }
          
      }
      this.postDataApiCallId = await this.apiCallNotification({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.postOrderData,
        type: "",
        body: body
      });
    }
    else {
      alert("Please select valid order templet")
    }
  }
  // Customizable Area End
}