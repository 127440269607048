import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  styled,
  Select,
  MenuItem,
  Grid,
  TextField,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ChangeEvent } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { createTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Cropper from "react-easy-crop"

interface UploadedFiles {
  id: number;
  file_name: string;
  file_url: string;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        {this.state.loader && <LinearProgress />}
        <Grid container>
          <Grid xs={12} sm={6} lg={6} md={6}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Box style={webStyle.uploadBtnWrapper}>
                <label htmlFor="filePicker">
                  <input
                    type="file"
                    onChange={this.onChangeFile}
                    multiple
                    hidden
                    id="filePicker"
                    data-testid="filePicker"
                  />
                  <Typography style={webStyle.uploadBtn}>
                    {configJSON.uploadBtnLabel}
                  </Typography>
                </label>
                {this.state.filesWeb.length > 0 && (
                  <Button
                    onClick={this.clearAllFile}
                    color="primary"
                    variant="contained"
                    style={webStyle.clearBtnStyle}
                    data-test-id="clearAllFile"
                  >
                    {configJSON.clearFileBtnLabel}
                  </Button>
                )}
                <Button
                  onClick={this.getUploadedFiles}
                  color="primary"
                  variant="contained"
                  data-testid="getUploadedFiles"
                >
                  {configJSON.getUploadedFileLabel}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ ...webStyle.clearBtnStyle, marginLeft: "15px" }}
                  data-test-id="formData"
                  onClick={this.getFormData}
                >
                  FormData
                </Button>
              </Box>
              <div>

                {this.state.formDataField.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.attributes.field_type === "dropdown" && (
                        <>
                          <Typography>{item.attributes.field_name}
                          </Typography>
                          <Select variant="outlined" data-test-id="handleCategoryChange" onChange={(event: ChangeEvent<{ name?: string; value: unknown; }>) => this.integerBoxOnChange(event, `field_name${index+1}`,`field_value${index+1}`,item.attributes.field_name,item.attributes.field_type)}
                            value={this.state.answerField[item.attributes.field_name]} fullWidth>
                            <MenuItem value="Select Category" style={{ display: "none" }}>Select Category</MenuItem>
                            <MenuItem value="Home Kit">Home Kit</MenuItem>
                            <MenuItem value="Lab Generated">Lab Generated</MenuItem>
                          </Select>
                        </>
                      )}
                      {item.attributes.field_type === "text" && (
                        <>
                          <Typography>{item.attributes.field_name}</Typography>
                          <TextField variant="outlined" fullWidth value={this.state.answerField[item.attributes.field_name]}
                            data-test-id="TextBox"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {this.integerBoxOnChange(event, `field_name${index+1}`,`field_value${index+1}`,item.attributes.field_name,item.attributes.field_type)}}
                          ></TextField>
                        </>
                      )}
                      {item.attributes.field_type === "integer" && (
                        <>
                          <Typography>{item.attributes.field_name}</Typography>
                          <TextField variant="outlined" fullWidth data-test-id="intergerBox" value={this.state.answerField[item.attributes.field_name]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>{this.integerBoxOnChange(event, `field_name${index+1}`,`field_value${index+1}`,item.attributes.field_name,item.attributes.field_type)}}
                          >
                          </TextField>
                        </>
                      )}
                      {item.attributes.field_type === "checkbox" && (
                        <div style={{ display: "flex" }}>
                          <Checkbox value={this.state.answerField[item.attributes.field_name]} data-test-id="checkBox" onChange={(event: ChangeEvent<HTMLInputElement>) =>{this.integerBoxOnChange(event, `field_name${index+1}`,`field_value${index+1}`,item.attributes.field_name,item.attributes.field_type)}} />
                          <Typography style={{ marginTop: "9px" }}>{item.attributes.field_name}</Typography>
                        </div>
                      )}
                      {item.attributes.field_type === "date" && (
                        <>
                          <Typography>{item.attributes.field_name}</Typography>
                          <TextField
                            id="date"
                            type="date"
                            fullWidth
                            data-test-id="dateBox"
                            value={this.state.answerField[item.attributes.field_name]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>{this.integerBoxOnChange(event, `field_name${index+1}`,`field_value${index+1}`,item.attributes.field_name,item.attributes.field_type)}}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      )}
                      {item.attributes.field_type === "tags" && (
                        <>
                          <Typography>{item.attributes.field_name}</Typography>
                          <TextField variant="outlined" fullWidth data-test-id="tagBox" value={this.state.answerField[item.attributes.field_name]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>{this.integerBoxOnChange(event, `field_name${index+1}`,`field_value${index+1}`,item.attributes.field_name,item.attributes.field_type)}}
                          >
                          </TextField>
                        </>
                      )}
                    </div>
                  );
                })}

              </div>

              <DragDropContext data-test-id="onDragEnd" onDragEnd={this.onDragEnd}>

                <Droppable droppableId="fileList">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.filesWeb.map((file: File, index: number) => {
                        const isPdf = this.getFileIcon(file.name);
                        return (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div style={webStyle.fileWrapper}>
                                  {isPdf ? <PictureAsPdfIcon /> : <ImageIcon />}
                                  <Typography style={webStyle.fileName}>{file.name}</Typography>
                                  <Button data-test-id={`removeFileWeTestId${index}`} onClick={() => this.removeFileWeb(index)}>
                                    <CloseIcon />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <Button
                style={webStyle.submitBtnStyle}
                onClick={this.uploadFile}
                color="primary"
                variant="contained"
                data-testid="submitBtn"
              >
                {configJSON.submitBtnLabel}
              </Button>
              < Button
                style={webStyle.submitBtnStyle}
                onClick={this.uploadFormData}
                color="primary"
                variant="contained"
                data-test-id="submitBtnFormData"
              >
                Submit Form Data
              </Button>
              {this.state.fileUploadedWEb && this.state.fileUploadedShow &&
                <div>
                  <Typography variant="h6" style={webStyle.margin}>Uploaded File</Typography>
                  <div>
                    {this.state.fileUploadedWEb?.map((item: UploadedFiles, index) => {
                      const isPdf = this.getFileIcon(item.file_name);
                      return <React.Fragment key={index}>
                        <div style={{ ...webStyle.margin, display: "flex" }}>
                          {isPdf ? <PictureAsPdfIcon /> : <ImageIcon />}
                          <Typography style={{ ...webStyle.fileName, cursor: "pointer" }} data-test-id="openUploadedFile" onClick={() => this.openUploadedFile(item)}>{item.file_name}</Typography>
                          <Button data-test-id="removeSingleUploadedFile" onClick={() => this.removeSingleUploadedFile(item.id)}>
                            <CloseIcon />
                          </Button>
                        </div>
                      </React.Fragment>
                    })}
                  </div>
                  <Button color="primary" variant="contained" data-test-id="removeAllUploaded" onClick={() => this.removeAllUploaded()}>Delete all uploaded file</Button>
                </div>
              }
            </Box>
          </Grid>
          <Grid xs={12} sm={6} lg={6} md={6}>
            <div style={{ textAlign: "center" }}>
              <Typography variant="h6">Profile Picture</Typography>
              <div style={webStyle.profileDiv}>
                <label htmlFor="imageupload">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handlePictureChange}
                    multiple
                    hidden
                    id="imageupload"
                    data-test-id="imageupload"
                  />
                  <Typography style={webStyle.uploadBtn}>
                    Upload Profile
                  </Typography>
                </label>
                {this.state.croppingInProgress && (
                  <div style={{ marginTop: 20 }}>
                    <BoxCropper>
                      <Cropper
                        image={this.state.profilePicture}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={3 / 2}
                        onCropChange={this.onCropChange}
                        onZoomChange={this.onZoomChange}
                        onCropComplete={this.handleCropComplete}
                        data-test-id="cropper"
                      />
                    </BoxCropper>
                    <div style={{ marginTop: 10 }}>
                      <button style={webStyle.uploadBtn} data-test-id="setProfile" onClick={this.handleSetProfilePicture}>Crop and Set Profile Picture</button>
                    </div>
                  </div>
                )}
                {this.state.isCropped && (
                  <div style={{ marginTop: 20 }}>
                    <h4>Profile Picture</h4>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                      <div
                        style={{
                          position: "relative",
                          width: "300px",
                          overflow: "hidden",
                          boxShadow: "0 0 32px rgba(0, 0, 0, 0.3)"
                        }}
                      >
                        <img src={this.state.croppedProfilePicture} alt="" style={{
                          ...this.state.imageStyle,
                          transformOrigin: "top left"
                        }} />
                      </div>
                    </div>
                    <button style={webStyle.removeImage} data-test-id="removeProfile" onClick={() => this.removeProfile()}>
                      Remove
                    </button>
                  </div>
                )}
              </div>
              <Button
                style={webStyle.submitBtnStyle}
                onClick={this.uploadProfile}
                color="primary"
                variant="contained"
                data-test-id="submitBtnProfile"
              >
                {configJSON.submitBtnLabel}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const BoxCropper = styled(Box)({
  "& .reactEasyCrop_Container": {
    position: "absolute",
    top: 310,
    bottom: 225,
    left: 462,
    right: 390
  }
})
const webStyle = {
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    marginTop: 20,
    marginBottom: 20,
  },
  fileWrapper: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    textDecoration: 'none',
    color: 'inherit',
    flex: 1,
    marginLeft: "10px"
  },
  submitBtnStyle: {
    marginTop: 20,
  },
  uploadBtnWrapper: {
    display: "flex",
    marginTop: 20,
  },
  uploadBtn: {
    padding: 7,
    paddingLeft: 15,
    paddingRight: 15,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#fff",
    borderRadius: 5,
    cursor: "pointer",
    marginRight: 15,
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  bulkFileWrapper: {
    border: "1px solid #ccc",
    padding: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  bulkFileTopWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textWrapper: {
    flex: 1,
  },
  fileItemWrapper: {
    display: "flex",
    border: "1px solid #ccc",
    padding: 10,
    marginTop: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  removeImage: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
    cursor: "pointer"
  },
  profileDiv: {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    marginTop: "20px"
  },
  margin: {
    marginTop: "10px"
  }
};
// Customizable Area End
