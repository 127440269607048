import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Button,
  styled,
} from "@material-ui/core";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return(
      <MainTermsConditionsContainer container>
        <Typography className="headingTermsConditions">
          {configJSON.termsConds}
        </Typography>
        <Box className="textMainContainer" >
          {this.state.termsCondsData.map((item, index) => {
            return (
              <Box key={index}>
                <h1 dangerouslySetInnerHTML={{ __html: item.title }}></h1>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                {item.acceptance_required &&
                  <Box className="btnMainContainer" >
                    <Button data-test-id="acceptTestId" className="loginButton" onClick={() => this.postAcceptDataShow("Accept",item.id)} disabled={this.state.isAgree} >Agree</Button>
                    <Button data-test-id="declineTestId" className="GuestButton" onClick={() => this.postAcceptDataShow("",item.id)}>Decline</Button>
                  </Box>
                }
              </Box>
            )
          })}
        </Box>
      </MainTermsConditionsContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainTermsConditionsContainer = styled(Grid)({
  width: "100%",
  "& .MuiButton-root":{
    textTransform: "initial"
  },
  "& .headingTermsConditions": {
    width:"100%",
    textAlign:"center",
    fontSize: "48px",
    fontWeight: 700,
    fontStyle: "normal",
    color: "#000"
  },
  "& .textMainContainer": {
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    width:"100%",
    margin:"20px 40px",
  },
  "& .btnMainContainer": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    width:"100%",
  },
  "& .loginButton": {
    width: "300px",
    height:"52px",
    background: "#000",
    border: "none",
    color: "#ffff",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderRadius: "8px",
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
  },
  "& .MuiButtonBase-root.Mui-disabled":{
    background: "gray !important",
  },
  "& .GuestButton": {
    width: "300px",
    height:"52px",
    background: "#fff",
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderRadius: "8px",
    border: "1px solid var(--Basic-Black, #000)",
    padding: "10px 16px",
    "&:hover": {
      background: "#fff",
    },
  },
});
export { TermsConditions }
// Customizable Area End
