import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Switch
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik } from "formik";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
const theme = createTheme({
  palette: {
    primary: {
      main: "#223bc9",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import * as Yup from 'yup';
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
   
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box>
            <Typography variant="h4" style={{ textAlign : 'center', marginBottom : '30px'}}>Email Notifications</Typography>
            <Formik
            enableReinitialize={true}
            initialValues={{
              email: "",
              comments: "",
              order_status: "",
              order_templates: "",
              order_date: "", // Add1 order_date field to initialValues
            }}
              validationSchema={this.validationSchemaConditions()}
            onSubmit={(values) => this.addOrder(values)}
           >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values // Accessing form values
            }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Box sx={webStyle.inputStyle} >
                  <Typography style={{ fontWeight : 'bold' }}>Email</Typography>
                      <Input
                        data-test-id={"emailInputTestID"}
                        type="text"
                        placeholder='add email-address'
                        fullWidth={true}
                        disableUnderline={true}
                        // value={this.state.txtInputValue}
                        name="email"
                        onChange={handleChange}
                    />
                      </Box>
                      <div style={webStyle.spanCss}>{errors.email}</div>
                      <Box sx={webStyle.inputStyle} >
                      <Typography style={{ fontWeight : 'bold' }}>Comments</Typography>
                      <Input
                        type={"text"}
                        data-test-id={"commentInputTestID"}
                        placeholder='add comments'
                        fullWidth={true}
                        disableUnderline={true}
                        name="comments"
                        onChange={handleChange}
                    />
                      </Box>
                      <span style={webStyle.spanCss}>{errors.comments}</span>
                  <Box sx={webStyle.inputStyle}>
                    <Typography style={{ fontWeight : 'bold' }}>Order Status</Typography>
                    <FormControl>
                      <Select
                        data-test-id={"orderStatusInputTestID"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.order_status} // Use values.order_status
                        onChange={handleChange}
                        name="order_status"
                        onBlur={handleBlur}
                        placeholder="Order Status"
                      >
                        <MenuItem value={'shipped'}>Shipped</MenuItem>
                        <MenuItem value={'confirm'}>Confirm</MenuItem>
                        <MenuItem value={'delivered'}>Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {touched.order_status && errors.order_status && (
                    <span style={webStyle.spanCss}>{errors.order_status}</span>
                  )}
                   <Box sx={webStyle.inputStyle}>
                    <Typography style={{ fontWeight : 'bold' }}>Order Templates</Typography>
                    <FormControl>
                      <Select
                        data-test-id={"orderTemplatesInputTestID"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.order_templates} // Use values.order_status
                        onChange={(event)=>this.handleChangeTemplate(event as React.ChangeEvent<{ name?: string; value: string; }>,handleChange)}
                        name="order_templates"
                        onBlur={handleBlur}
                        placeholder="Order Templates"
                      >
                        {this.state.emailTempletOptions.map((subject, index) => (
                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                         ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {touched.order_templates && errors.order_templates && (
                    <span style={webStyle.spanCss}>{errors.order_templates}</span>
                  )}
                  <Box sx={webStyle.inputStyle}>
                    <Typography style={{ fontWeight : 'bold' }}>Order Date</Typography>
                    <FormControl>
                    <TextField
                      data-test-id={"orderDateInputTestID"}
                      name='order_date' 
                      id="date"
                      type="datetime-local" // Change type to datetime-local
                      value={values.order_date} // Use values.order_date
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.order_date && !!errors.order_date} // Check for error state
                      helperText={touched.order_date && errors.order_date} // Display error message
                      style={webStyle.textField}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
                    </FormControl>
                  </Box>
                  <div>
                    <Button style={webStyle.buttonStyle as React.CSSProperties} type="submit">Send email</Button>
                  <Button style={webStyle.buttonStyle as React.CSSProperties} data-test-id="dynamicForm" 
                  
                  onClick={()=>this.dynamicForm( values)}

                  >Send Dynamic</Button>
                  </div>
                </form>
              );
            }}
          </Formik>
          </Box>
          <Box sx={webStyle.mainWrapper}>
              <div style={{ fontWeight : 'bold' }}>Notification Setting</div>
              <div>Payment Received <Switch
                data-test-id="notificationTyoeTestID"
                checked={this.state.notificationType}
                onChange={() => this.handleChange('payment_received')}
                color="primary"
                name="checkedB"
              /></div> 
               <div>Order Updates <Switch
                data-test-id="notificationTyoeTestID1"
                checked={this.state.notificationType1}
                onChange={() => this.handleChange('order_updates')}
                color="primary"
                name="checkedA"
              /></div>
              <div>Verification Email <Switch
                data-test-id="notificationTyoeTestID2"
                checked={this.state.notificationType2}
                onChange={() => this.handleChange('verification_email')}
                color="primary"
                name="checkedC"
              /></div>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: '5px',
    marginTop: '10px'
  },
  inputStyle1: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    color: "#fff",
    backgroundColor: 'black',
    fontWeight: 'bold'
  },
  addMargin: {
    marginTop : '20px'
  },
  spanCss: {
    color : 'red',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '250px',
  },
};
// Customizable Area End